import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";

import styles from "./blog.module.scss";

const BlogLink = ({ title, subtitle, link }) => (
  <Link to={link}>
    <span className={styles.linkTitle}>{title}</span>
    <br />
    <span className={styles.gray}>{subtitle}</span>
  </Link>
);

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  console.log(frontmatter);
  return (
    <Layout>
      <div className="blog-post-container">
        <div className="blog-post">
          <div className={styles.title}>
            <img src={frontmatter.backgroundImage} alt="" />
            <h1>{frontmatter.title}</h1>
          </div>
          <div
            className={styles.blogContent}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      <section className={styles.continueReading}>
        <h2>Continue Reading</h2>
        <BlogLink
          link="/posts/mimir-workforce/"
          title="Mimir Workforce"
          subtitle="Building a product from scratch"
        />
        <BlogLink
          link="/posts/mimir-classroom/"
          title="Mimir Classroom"
          subtitle="Tackling design debt"
        />
        <BlogLink
          link="/posts/mimir-design-system/"
          title="Mimir Design System"
          subtitle="Evolving a design system"
        />
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        backgroundImage
      }
    }
  }
`;
